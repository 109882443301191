<template>
	<PaymentChannelForm
		:is-submitting="bankChannel.isLoading"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PaymentChannelForm from '@/components/PaymentChannelForm.vue';

export default {
	name: 'PaymentCreditCardBankChannelCreate',

	components: {
		PaymentChannelForm,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('payments', {
			bankChannel: 'bankChannel',
		}),
	},
	methods: {
		...mapActions({
			createBankChannel: 'payments/createBankChannel',
		}),

		async handleSubmit(params = {}) {
			await this.createBankChannel({
				methodId: this.$route.params.methodId,
				params,
			});

			this.$router.push({
				name: 'PaymentMethodSetting',
				params: {
					id: this.$route.params.id,
					methodId: this.$route.params.methodId,
				},
			});
		},
	},
};
</script>
