<template>
	<form
		class="main-wrapper mt-4"
	>
		<CRow>
			<CCol md="12">
				<h4 class="mb-4">
					Bank channel info
				</h4>
			</CCol>
			<CCol md="12" class="form-group-image">
				<div class="label mb-2">
					Channel logo
				</div>
				<div class="d-flex" :class="{ 'is-invalid': $v.localLogo.$error }">
					<div class="image-link">
						<a
							href="#"
							@click.prevent="handleClickLogo"
						>
							<div v-if="!localLogo" :class="{ 'image-placeholder': !localLogo }">
								<img src="/assets/images/product-placeholder.svg" alt="Add image" class="mb-1">
								Add image
							</div>
							<img
								v-else
								:src="localLogo.imageUrls.thumbnail"
								class="image-preview"
								alt=""
							>
						</a>
						<CButton
							v-if="localLogo"
							class="btn-delete-image"
							@click="handleDeleteLogo"
						>
							<CIcon name="cil-trash" />
						</CButton>
					</div>
					<ul class="image-helper pl-4">
						<li>- Recommended resolution W64 x H64 px</li>
						<li>- Maximum 20 images per upload</li>
						<li>- Maximum upload size per file is 10MB</li>
						<li>- Supported PNG, JPG, JPEG, GIF, Webp</li>
					</ul>
				</div>
				<div class="form-group-image-error invalid-feedback mb-3">
					{{ $t('global.error.required') }}
				</div>
			</CCol>
			<CCol md="12">
				<CInput
					v-model.trim="$v.localNameEN.$model"
					:is-valid="!$v.localNameEN.$error && null"
					label="Channel name (EN)"
					:invalid-feedback="$t('global.error.required')"
				/>
			</CCol>
			<CCol md="12">
				<CInput
					v-model.trim="$v.localNameTH.$model"
					:is-valid="!$v.localNameTH.$error && null"
					label="Channel name (TH)"
					:invalid-feedback="$t('global.error.required')"
				/>
			</CCol>
			<CCol md="6">
				<div class="form-group form-group-switch my-4">
					<label class="toggle-label" for="status">Status</label>
					<CSwitch
						id="status"
						:checked.sync="localStatus"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span>Active</span>
				</div>
			</CCol>
		</CRow>
		<hr class="mt-3 mb-5">
		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:is-edit="isEdit"
			:remove-text="isEdit && isDeletable ? 'Remove channel' : null"
			@onRemove="isEdit && isDeletable ? $refs['modal-remove'].open() : null"
			@onConfirm="handleSubmit"
			@onCancel="redirectToPaymentSetting"
		/>
		<ModalMediaLibrary
			ref="media-library"
			type="single"
			@onSubmit="handleSubmitLogo"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteBankChannel.bind(null, { methodId: $route.params.methodId, channelId: this.$route.params.channelId })"
			title="Remove this channel?"
			description="Are you sure to remove this channel?"
			@onSuccess="redirectToPaymentSetting"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';

export default {
	name: 'PaymentChannelForm',

	validations: {
		localNameEN: {
			required,
		},
		localNameTH: {
			required,
		},
		localLogo: {
			required,
		},
	},
	components: {
		ModalMediaLibrary,
	},
	props: {
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		logo: {
			type: Object,
			default: () => ({}),
		},
		nameEn: {
			type: String,
			default: null,
		},
		nameTh: {
			type: String,
			default: null,
		},
		status: {
			type: Boolean,
			default: false,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		isDeletable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			localLogo: null,
			localNameEN: null,
			localNameTH: null,
			localStatus: true,
		};
	},
	mounted() {
		if (this.isEdit) {
			this.localLogo = this.logo;
			this.localNameEN = this.nameEn;
			this.localNameTH = this.nameTh;
			this.localStatus = this.status;
		}
	},
	methods: {
		...mapActions({
			deleteBankChannel: 'payments/deleteBankChannel',
		}),

		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			this.$emit('onSubmit', {
				logo_file_id: this.localLogo.id,
				name_en: this.localNameEN,
				name_th: this.localNameTH,
				is_active: this.localStatus,
			});
		},
		handleClickLogo() {
			this.$refs['media-library'].open();
		},
		handleSubmitLogo(files) {
			this.localLogo = files[0];
			this.isValidImage = true;
		},
		handleDeleteLogo() {
			this.localLogo = null;
		},
		redirectToPaymentSetting() {
			this.$router.push({
				name: 'PaymentMethodSetting',
				params: {
					id: this.$route.params.id,
					methodId: this.$route.params.methodId,
				},
			});
		},
	},
};
</script>


<style lang="scss" scoped>
	.image-placeholder {
		@include typo-helper-text;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: rem(78);
		height: rem(78);
		background-color: $color-gray-100;
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		color: $color-black-45;

		img {
			width: rem(32);
			height: rem(32);
		}
	}

	.image-helper {
		list-style: none;
		margin: 0;

		li {
			@include typo-body-2;

			color: $color-black-45;
		}
	}

	.image-link {
		position: relative;
		display: block;

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	.image-preview {
		width: rem(78);
		height: rem(78);
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		object-fit: contain;
	}

	.form-group-image {
		a {
			text-decoration: none;
		}

		.is-invalid {
			.image-placeholder,
			.image-preview {
				border-color: $color-alert;
			}
		}
	}

	.form-group-image-error {
		display: block;
		visibility: hidden;
	}

	.is-invalid ~ .invalid-feedback {
		visibility: visible;
	}

	.btn-delete-image {
		position: absolute;
		right: rem(8);
		bottom: rem(8);
		width: rem(20);
		height: rem(20);
		box-shadow: none;
		padding: 0;
		border-radius: 2px;
		background-color: $color-black-45;

		svg {
			width: rem(12) !important;
			color: $color-white;
		}
	}
</style>
